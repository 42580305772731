import React from "react";
import { ReactComponent as Fb } from '../assets/fb.svg'
import { ReactComponent as Telegram } from '../assets/telegram.svg'
import { ReactComponent as In } from '../assets/in.svg'
import { ReactComponent as Email } from '../assets/email.svg'
import { ReactComponent as Phone } from '../assets/phone.svg'
import { ReactComponent as Location } from '../assets/location.svg'




const Contacts = () => {
    return (
        <div className="contacts_container">
            <div className="contacts_wrapper">
            <h3>Contacts</h3>
            <div className="icons_container">
                <Fb className="fb"/>
                <Telegram className="tg" />
                <In className="in"/>
            </div>
            <div>
                <div className="contacts_info">
                    <div className="flex_2">
                        <Email />
                        <p>info@armmath.com</p>
                    </div>
                    <div  className="flex_2" >
                        <Phone />
                        <p>+374 (55) 30-35-16</p>
                    </div>
                    <div  className="flex_2" >
                        <Location />
                        <p>Yerevan, Armenak Armenakyan St 2/5, 0047 </p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Contacts