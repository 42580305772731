import React from "react";
import HeaderMobile from "../../components/HeaderMobile";
import GridMobile from "../../components/GridMobile";
import Page from "../../components/Page";
import { AboutUsDescription } from "../../constants";
import PageDescription from "../../components/PageDescription";

const AbooutUsMobile = ({ more, setMore, paginate }) => {
  return (
    <>
      <HeaderMobile />
      <GridMobile
        location="about"
        page={<Page page="3" width={481} bottom={true} />}
        title="About Us"
        paginate={paginate}
        description={
          <PageDescription
            content={<AboutUsDescription
              mobile={true}
              more={more}
            />}
            mobile={true}
            location="about"
          />}
        more={more}
        setMore={setMore}
      />

    </>
  );
};

export default AbooutUsMobile;
