import React from "react";
import HeaderMobile from "../../components/HeaderMobile";
import GridMobile from "../../components/GridMobile";

const ContactsMobile = () => {
    return (
        <>
            <HeaderMobile />
            <GridMobile
            location={'contacts'}
            paginate={'34px'}
             />
        </>
    )
}

export default ContactsMobile