import React, { useEffect } from "react";
import PageCard from "./PageCard";
import Prediction from "./PredictionCard";
import Error from "./Error";
import AboutUsImgContent from "./AboutUsImgContent";
import { PredictionDescription } from "../constants";
import {
  teamImgMob1,
  teamImgMob2,
  teamImgMob3,
  teamMobInfo1,
  teamMobInfo2,
  teamMobInfo3,
}
  from "../constants";
import TeamImg from "./TeamImg";
import TeamInfo from "./TeamInfo";
import Contacts from "./Contacts";
import { ReactComponent as Arrow } from '../assets/arrow.svg'
import { ReactComponent as Circle } from '../assets/circle.svg'
import { ReactComponent as ArrowLeft } from '../assets/arrowleft.svg'


const GridMobile = ({
  location,
  imageSmall,
  page,
  paginate,
  description,
  title,
  values,
  setValues,
  prediction,
  setPrediction,
  more,
  setMore,
  slideMembers,
  setSlideMembers
}) => {
  const { protonation, solubility, volatility } = { ...prediction };

  const readMoreRight = () => {
    setMore(more + 1)
  }
  const readMoreLeft = () => {
    setMore(more - 1)
  }




  return (
    <div
      className={
        location === "homepage" ||
          location === "about" ||
          protonation ||
          solubility ||
          volatility
          ? "grid_mob_homepage"
          : "grid_mobile"
      }
      style={location === 'contacts' ? { gridTemplateRows: 'repeat(4, 1fr)' } : {}}
    >
      <div
        className="main_1 grid_1"
        style={
          location === "projects"
            ? { borderRight: "none", borderBottom: "none" }
            : location === "about" ? { minHeight: '250px' } : location === 'contacts' ? { borderRight: "1px solid rgba(40, 56, 53, 0.15)" } : {}
        }
      >
        <div>
          {location === "projects" && (
            <Prediction
              prediction={prediction}
              setPrediction={setPrediction}
              title="Protonation percentage"
              setValues={setValues}
              values={values}
              width={481}
            />
          )}
          {protonation && (
            <div
              className="protonation_value"
              style={solubility || volatility ? { background: "#36635C" } : {}}
            >
              <div className="value">
                {values &&
                  !isNaN(Number(values)) &&
                  `Protonation percentage = ${values} %`}
                {values && isNaN(Number(values)) && <Error error={values} />}
              </div>
            </div>
          )}
          {solubility && (
            <div
              className="protonation_value"
              style={solubility || volatility ? { background: "#36635C" } : {}}
            >
              <div className="value">
                {values &&
                  !isNaN(Number(values)) &&
                  `Solubility = ${values} g/l`}
                {values && isNaN(Number(values)) && <Error error={values} />}
              </div>
            </div>
          )}
          {volatility && (
            <div
              className="protonation_value"
              style={solubility || volatility ? { background: "#36635C" } : {}}
            >
              <div className="value">
                {values &&
                  !isNaN(Number(values)) &&
                  `Volatility = ${values} % g/l`}
                {values && isNaN(Number(values)) && <Error error={values} />}
              </div>
            </div>
          )}
        </div>
        {location === "homepage" && (
          <div>
            <img
              src="./images/homepage.png"
              className="img_homepage_mob"
              alt="homepage"
            />
          </div>
        )}
        {location === "homepage" && (
          <PageCard
            page="2"
            className="homepage_card"
            location={location}
            width={481}
          />
        )}
        {location === "about" && (
          <PageCard page="4" location={location} mobile={true} />
        )}
        {location === "about" && <AboutUsImgContent />}
        {location === 'team' &&
          teamImgMob1.map((img, i) => (
            i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
          ))
        }
      </div>
      <div
        className="main_2 grid_2 border"
        style={
          location === "projects" && !protonation && !solubility && !volatility
            ? { borderRight: "none" }
            : location === "about"
              ? { borderTop: "1px solid rgba(40, 56, 53, 0.15)" }
              : location === 'team' && slideMembers !== 4 ?
                { borderRight: "none", borderBottom: 'none' } :
                {}
        }
      >
        {location === "projects" &&
          !protonation &&
          !volatility &&
          !solubility && (
            <Prediction
              prediction={prediction}
              setPrediction={setPrediction}
              title="Solubility"
              setValues={setValues}
              values={values}
              width={1140}
            />
          )}
        {location === "projects" && (protonation || solubility || volatility) && (
          <div className="protonation_title">
            <h2>
              Prodiction of{" "}
              {protonation
                ? "Protonation"
                : solubility
                  ? "Solubility"
                  : "Volatility"}
            </h2>
          </div>
        )}
        {location === "about" && page}
        {location === 'team' &&
          teamImgMob2.map((img, i) => (
            i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
          ))
        }
      </div>
      <div
        className="main_3 grid_3"
        style={
          protonation || solubility || volatility || location === "about"
            ? { borderRight: "1px solid rgba(40, 56, 53, 0.15)" }
            : (location === 'team' && slideMembers !== 4) || location === 'contacts' ? { borderBottom: 'none' } : {}
        }
      >
        {location === "projects" &&
          !protonation &&
          !solubility &&
          !volatility && (
            <Prediction
              prediction={prediction}
              setPrediction={setPrediction}
              title="Volatility"
              width={1140}
              setValues={setValues}
              values={values}
            />
          )}
        {location === "about" && (
          <h2 className="title" style={{ textAlign: "center", top: '25%' }}>
            {title}
          </h2>
        )}
        {location === 'team' &&
          teamImgMob3.map((img, i) => (
            i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
          ))
        }
        {location === 'contacts' && <div className="green"></div>}
      </div>
      <div
        className="main_4 grid_4"
        style={
          location === "about"
            ? { borderRight: "1px solid rgba(40, 56, 53, 0.15)" }
            : location === 'contacts' ? {
              gridArea: '2 / 1 / 3 / 4',
              minHeight: '250px'
            } : {}
        }
      >
        {location === "projects" &&
          !protonation &&
          !solubility &&
          !volatility && (
            <PageCard
              page="3"
              location={location}
              prediction={prediction}
              setPrediction={setPrediction}
            />
          )}
        {imageSmall}
        {(protonation || solubility || volatility) &&
          (setPrediction,
            (
              <PageCard
                page="2"
                prediction={prediction}
                location={location}
                width={1140}
                setPrediction={setPrediction}
              />
            ))}
        {location === 'team' &&
          teamMobInfo1.map((item, i) => (
            i === slideMembers &&
            <TeamInfo
              key={i}
              name={item.name}
              surname={item.surname}
              position={item.position}
              bg={item.bg}
              slideMembers={slideMembers}
              mobile={true}
            />
          ))}
        {location === "contacts" && <Contacts />}
      </div>
      {
        location !== "contacts" &&
        <div className="main_5 grid_5 border" style={location === 'team' && slideMembers !== 4 ? { borderRight: "none", borderBottom: 'none' } : {}}>
          {location === "homepage" && page}
          {(protonation || solubility || volatility) && (
            <PredictionDescription
              width={481}
              mobile={true}
              prediction={prediction}
            />
          )}
          {location === 'about' && description}
          {location === 'team' &&
            teamMobInfo2.map((item, i) => (
              i === slideMembers &&
              <TeamInfo
                key={i}
                name={item.name}
                surname={item.surname}
                position={item.position}
                bg={item.bg}
                slideMembers={slideMembers}
                mobile={true}
              />
            ))}
        </div>
      }
      {
        location !== 'contacts' &&
        <div className="main_6 grid_6" style={location === 'team' && slideMembers !== 4 ? { borderBottom: 'none' } : {}} >
          {location === "homepage" && (
            <h2 className="title" style={{ textAlign: "center" }}>
              {title}
            </h2>
          )}
          {location === 'team' &&
            teamMobInfo3.map((item, i) => (
              i === slideMembers &&
              <TeamInfo
                key={i}
                name={item.name}
                surname={item.surname}
                position={item.position}
                bg={item.bg}
                slideMembers={slideMembers}
                mobile={true}
              />
            ))}
        </div>
      }
      {
        location !== 'contacts' &&
        <div className="main_7 grid_7">
          {location === "projects" &&
            !protonation &&
            !solubility &&
            !volatility &&
            page}
          {location === 'team' && slideMembers !== 0 &&
            <div className="arrow_tablet" >
              <div className="team_arrow"
                onClick={() => setSlideMembers(slideMembers - 1)}
              >
                <ArrowLeft className="arrow" />
                <Circle className="circleleft" />
              </div>
            </div>}
        </div>
      }
      {
        location !== 'contacts' &&
        <div className="main_8 grid_8">
          {location === "homepage" && description}
          {location === "projects" &&
            !protonation &&
            !solubility &&
            !volatility && <h2 className="title top">{title}</h2>}
          {(location === "homepage" ||
            location === "about" ||
            protonation ||
            solubility ||
            volatility) && (
              <div className="progress_wrapper" style={{ top: "80%" }}>
                <div className="progress">
                  <div className="line" style={{ left: paginate }}></div>
                </div>
              </div>
            )}
          {location === 'about' && more !== 1 &&
            <div className="arrow_left" onClick={readMoreLeft}>
              <Circle className="circle" />
              <ArrowLeft className="arrow" />
            </div>}
        </div>
      }
      {
        location !== 'contacts' &&
        <div className="main_9 grid_9">
          {location === "about" && more !== 3 &&
            <div className="read">Read</div>}
          {location === 'team' && slideMembers !== 4 &&
            <div className="arrow_wrapper">
              <div className="team_arrow"
                onClick={() => setSlideMembers(slideMembers + 1)}
              >
                <Arrow className="arrow" />
                <Circle className="circle" />
              </div>
            </div>
          }
        </div>
      }
      <div className="main_10 grid_10" style={location === "contacts" ? { gridArea: "3/1/4/2" } : {}}>
        {location === "projects" &&
          !protonation &&
          !solubility &&
          !volatility &&
          description}
        {location === 'about' && more !== 3 &&
          <div className="slide_text">
            <p>more</p>
            <div className="flex_2" onClick={readMoreRight}>
              <Arrow className="arrow" />
              <Circle className="circle" />
            </div>
          </div>}
        {location === 'team' && page}
      </div>
      <div className="main_11" style={location === "contacts" ? { gridArea: "3/2/4/3" } : {}}>
        {location === "team" && (
          <h2 className="title" style={{ textAlign: "center" }}>
            {title}
          </h2>
        )}

      </div>
      {
        location !== "homepage" && (
          <>
            <div className="main_12" style={location === "contacts" ? { gridArea: "3/3/4/4" } : {}}>
            {location === 'contacts' && <div className="green"></div>}
            </div>
            <div className="main_13" style={location === "contacts" ? { gridArea: "4/1/5/2" } : {}}>
              {location !== "homepage" &&
                location !== "about" &&
                !protonation &&
                !solubility &&
                !volatility && (
                  <div className="progress_wrapper" style={{ top: "80%" }}>
                    <div className="progress">
                      <div className="line" style={{ left: paginate }}></div>
                    </div>
                  </div>
                )}
            </div>
            <div className="main_14" style={location === "contacts" ? { gridArea: "4/2/5/3" } : {}}> </div>
            <div className="main_15" style={location === "contacts" ? { gridArea: "4/3/5/4" } : {}}> </div>
          </>
        )
      }
    </div >
  );
};

export default GridMobile;
