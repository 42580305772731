import React from "react";
import { ReactComponent as Fb } from '../assets/fb.svg'
import { ReactComponent as Telegram } from '../assets/telegram.svg'
import { ReactComponent as In } from '../assets/in.svg'
import { ReactComponent as Email } from '../assets/email.svg'
import { ReactComponent as Phone } from '../assets/phone.svg'
import { ReactComponent as Location } from '../assets/location.svg'


const FooterTablet = ({ paginate }) => {
    return (
        <div className="footer_tablet">
            <div className="grid_1 border">
                <div className="progress_wrapper" style={{ top: '50%' }}>
                    <div className="progress" >
                        <div className="line" style={{ left: paginate }} >
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid_2 border">
                <div className="icons_tablet">
                    <Fb className="fb" />
                    <Telegram className="tg" />
                    <In className="in" />
                </div>
            </div>
            <div className="grid_3 border">
                <div className="footer_info">
                    <div className="flex_2">
                        <Email className="email"/>
                        <p>info@armmath.com</p>
                    </div>
                    <div className="flex_2" >
                        <Phone className="phone"/>
                        <p>+374 (55) 30-35-16</p>
                    </div>
                    <div className="flex_2">
                        <Location className="location" />
                        <p>Yerevan, Armenak Armenakyan St 2/5, 0047 </p>
                    </div>
                </div>
            </div>
            <div className="grid_4">
            </div>
        </div>
    )
}

export default FooterTablet