import React, { useRef, useEffect, useState } from 'react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';






const ContactUs = ({ setShowContact }) => {
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    message: ''
  })
  const ref = useRef(null)
  const form = useRef();
  const emailValidation = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{2,9}[\.][a-z]{2,5}/g;


  const Success = () => {
    toast.success("Success!", {
      position: toast.POSITION.TOP_CENTER
    });

  };

  const Error = () => {
    toast.error("Please fill input field!", {
      position: toast.POSITION.TOP_CENTER
    });

  };


  const sendEmail = (e) => {
    e.preventDefault()
    const { first_name, last_name, email, message } = values

    if (first_name && last_name && email.match(emailValidation) && message) {
      emailjs.sendForm('service_qjkrvuc', 'template_278ijet', form.current, 'YHwDIOVMJGKYeVIa6')
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
        Success()
        setTimeout(() => {
          setShowContact(false)
        }, 1500)
    }else {
      Error()
    }
  } 

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({
      ...values,
      [name]: value
    })
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowContact(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref.current])

  const el = (document.getElementsByClassName("grid_mob_homepage")[0] || document.getElementsByClassName("grid_mobile")[0])

  return (
    <>
      <div className='contact_us' style={el?.scrollHeight? {height: el.scrollHeight }: {}}>
        <div className='background' ></div>
        <div className='contact_us_content' ref={ref}>
          <h3>Contact Us</h3>
          <h4>Get in touch</h4>
          <form ref={form} onSubmit={sendEmail}>
            <div className='form'>
              <input
                placeholder='First Name'
                name='first_name'
                onChange={(e) => handleChange(e)}
              />
              <input
                placeholder='Last Name'
                name="last_name"
                onChange={(e) => handleChange(e)}
              />
              <input
                placeholder='E-mail'
                name='email'
                onChange={(e) => handleChange(e)}
              />
              <textarea
                placeholder='Message'
                name='message'
                onChange={(e) => handleChange(e)}
              ></textarea>
              <button type='submit'>Submit</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        autoClose={1000}
      />
    </>
  )
}

export default ContactUs