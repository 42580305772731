import React, { useState } from "react";
import Header from "../../components/Header";
import Grid from "../../components/Grid";
import Footer from "../../components/Footer";
import Page from "../../components/Page";
import PageDescription from "../../components/PageDescription";
import TeamTablet from "../TeamTablet";
import TeamMobile from "../TeamMobile";
import { logoName } from "../../constants";

const width = window.innerWidth

const Team = () => {
  const [slideMembers, setSlideMembers] = useState(0)


  if (width < 1140 && width > 481 ) {
    return <TeamTablet slideMembers={slideMembers} setSlideMembers={setSlideMembers} paginate={'24px'}  />
  }
  if (width < 481 ) {
    return <TeamMobile slideMembers={slideMembers} setSlideMembers={setSlideMembers} paginate={'24px'}  />
  }

  return (
    <>
      <Header page='team' />
      <Grid
        page={<Page page="4" />}
        logoName={logoName}
        description={<PageDescription
          title="Our Team"
        />}
        location="team"
        slideMembers={slideMembers}
        setSlideMembers={setSlideMembers}
      />
      <Footer paginate={'30px'} />
    </>
  )
}

export default Team