import React from "react";
import HeaderTablet from "../../components/HeaderTablet";
import FooterTablet from "../../components/FooterTablet";
import GridTablet from "../../components/GridTablet";
import Error from "../../components/Error";
import Page from "../../components/Page";
import PageDescription from "../../components/PageDescription";
import { PredictionDescription, projectsContent } from "../../constants";

const ProjectsTablet = ({values, setValues, prediction, setPrediction, paginate}) => {
  const {protonation, solubility, volatility} = {...prediction}
  return (
    <>
      {(protonation || solubility || volatility) && (
        <div
          className="protonation_value"
          style={solubility || volatility ? { background: "#36635C" } : {}}
        >
          {protonation && (
            <div className="value">
              {values && !isNaN(Number(values)) && `Protonation percentage = ${values} %`}
              {values && isNaN(Number(values)) && <Error error={values} />}
            </div>
          )}
          {solubility && (
            <div className="value">
              {values &&
                !isNaN(Number(values)) &&
                `Solubility = ${values} g/l`}
              {values && isNaN(Number(values)) && <Error error={values} />}
            </div>
          )}
          {volatility && (
            <div className="value">
              {values &&
                !isNaN(Number(values)) &&
                `Volatility = ${values} % g/l`}
              {values && isNaN(Number(values)) && <Error error={values} />}
            </div>
          )}
        </div>
      )}
      {(protonation || solubility || volatility) && (
        <PredictionDescription width={1140} prediction={prediction} />
      )}
      <HeaderTablet />
      <GridTablet
       location="projects" 
       page={
        <Page
          location="projects"
          page={"2"}
          prediction={prediction}
        />
      }
      description={
        <PageDescription
          location="projects"
          title={"Pilot Projects for Testing"}
          content={projectsContent}
          prediction={prediction}
        />
      }
       values={values}
       setValues={setValues}
       prediction={prediction}
       setPrediction={setPrediction}
       />
      <FooterTablet  paginate={paginate}/>
    </>
  );
};

export default ProjectsTablet;
