import React from "react";
import { ReactComponent as Mouse } from '../assets/mouse.svg'
import { ReactComponent as Fb } from '../assets/fb.svg'
import { ReactComponent as Telegram } from '../assets/telegram.svg'
import { ReactComponent as In } from '../assets/in.svg'
import { ReactComponent as Email } from '../assets/email.svg'
import { ReactComponent as Phone } from '../assets/phone.svg'
import { ReactComponent as Location } from '../assets/location.svg'


const Footer = ({paginate}) => {
    return (
        <div className="grid footer">
            <div className="grid_1 border">
                <div className="progress_wrapper">
                    <Mouse />
                    <div className="progress" >
                        <div className="line" style={{left: paginate }}>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid_2 border">

            </div>
            <div className="grid_3 border">
                <div className="icons">
                    <Fb className="fb" />
                    <Telegram className="tg" />
                    <In  className="in"/>
                </div>
            </div>
            <div className="grid_4 border">
                <div className="flex_3">
                    <Email />
                    <p>info@armmath.com</p>
                    <Phone className="phone" />
                </div>
            </div>
            <div className="grid_5 border">
                <div className="flex_3">
                    <p>+374 (55) 30-35-16</p>
                    <Location  className="location"/>
                    <p>Yerevan, Armenak Armenakyan St 2/5, 0047</p>
                </div>
            </div>
            <div className="grid_6 "> </div>
        </div>

    )
}

export default Footer