import React from "react";
import { RouterProvider } from './providers';


function App() {
  return (
      <RouterProvider />
  );
}

export default App;
