import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Grid from "../../components/Grid";
import Page from "../../components/Page";
import PageDescription from "../../components/PageDescription";
import Error from "../../components/Error";
import ProjectsTablet from "../ProjectsTablet";
import ProjectsMobile from "../ProjectsMobile";
import { projectsContent, PredictionDescription } from "../../constants";


const width = window.innerWidth;
const height = window.innerHeight

const Projects = () => {
  const [prediction, setPrediction] = useState({
    protonation: null,
    solubility: null,
    volatility: null,
  });
  const [backPred, setBackPred] = useState(false);
  const [values, setValues] = useState("");
  const { protonation, solubility, volatility } = { ...prediction };

  const logoName = (
    <p
      className="logoName"
      id={
        protonation || solubility || volatility
          ? "fade-inpredlogo"
          : "fade-inlogo"
      }
    >
      ArmMath
    </p>
  );

  if (width < 1140 && width > 481 ) {
    return (
      <ProjectsTablet
        values={values}
        setValues={setValues}
        prediction={prediction}
        setPrediction={setPrediction}

      />
    );
  }
  if (width < 481) {
    return (
      <ProjectsMobile
        values={values}
        setValues={setValues}
        prediction={prediction}
        setPrediction={setPrediction}
      />
    );
  }


  return (
    <>
      <Header page="projects" />
      {(protonation || solubility || volatility) && (
        <div
          className="protonation_value"
          id={backPred ? "fade-outpred" : "fade-inpredValue"}
          style={solubility || volatility ? { background: "#36635C" } : {}}
        >
          {protonation && (
            <div className="value">
              {values && !isNaN(Number(values)) && `Protonation percentage = ${values} %`}
              {values && isNaN(Number(values)) && <Error error={values} />}
            </div>
          )}
          {solubility && (
            <div className="value">
              {values &&
                !isNaN(Number(values)) &&
                `Solubility = ${values} g/l`}
              {values && isNaN(Number(values)) && <Error error={values} />}
            </div>
          )}
          {volatility && (
            <div className="value">
              {values &&
                !isNaN(Number(values)) &&
                `Volatility = ${values} Pa m³/mol`}
              {values && isNaN(Number(values)) && <Error error={values} />}
            </div>
          )}
        </div>
      )}
      {(protonation || solubility || volatility) && (
        <PredictionDescription backPred={backPred} prediction={prediction} />
      )}
      <Grid
        page={
          <Page
            backPred={backPred}
            location="projects"
            page={"2"}
            prediction={prediction}
          />
        }
        description={
          <PageDescription
            location="projects"
            title={"Pilot Projects for Testing"}
            content={projectsContent}
            prediction={prediction}
            backPred={backPred}
          />
        }
        logoName={logoName}
        location="projects"
        setPrediction={setPrediction}
        prediction={prediction}
        backPred={backPred}
        setBackPred={setBackPred}
        setValues={setValues}
        values={values}
      />
      <Footer paginate={"10px"} />
    </>
  );
};

export default Projects;
