import React from "react";
import PredictionContent from "./PredictionContent";
import {
    solubilityTitle,
    volatilityTitle,
    protonationTitle,
    solubilityPlaceholder,
    volatilityPlaceholder,
    protonationPlaceholder
} from "../constants";
import { ReactComponent as Arrow } from '../assets/arrowWhite.svg'


const Prediction = ({ title, prediction, setPrediction, backPred, setBackPred, setValues, values, width }) => {
    const { protonation, solubility, volatility } = { ...prediction }

    const handleClick = (title) => {
        if(!width) {
            setBackPred(null)
        }
        setValues('')
        if (title === "Solubility") {
            setPrediction({
                solubility: true,
            })
        }
        if (title === "Volatility") {
            setPrediction({
                volatility: true,
            })
        }
        if (title === "Protonation percentage") {
            setPrediction({
                protonation: true,
            })
        }
    }

    return (
        (((!protonation && !solubility && !volatility) || backPred) ? (
            <div
                className="prediction"
                style={title === "Solubility" ? { background: '#C09D81' } : title === "Volatility" ? { background: '#C6BCB0' } : {}}
                id={"fade-inpred1"}
            >
                <h3>
                    Prediction of <br></br>
                    {title}
                </h3>
                <div className="flex" onClick={() => handleClick(title)}>
                    <Arrow />
                </div>
            </div>
        ) : (
            <PredictionContent
                title={solubility ? solubilityTitle : volatility ? volatilityTitle : protonationTitle}
                solubility={solubility}
                placeholder={solubility ? solubilityPlaceholder : volatility ? volatilityPlaceholder : protonationPlaceholder}
                volatility={volatility}
                backPred={backPred}
                setValues={setValues}
                protonation={protonation}
                values={values}
            />
        ))
    )
}

export default Prediction

