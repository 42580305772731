const width = window.innerWidth
export const homepageContent = (
  <p>
    ArmMath is a team of experienced and dedicated
    armenian mathematicians, chemists, programmers and
    engineers developing mathematical models for solving
    problems in different domains.
  </p>
);

export const projectsContent = (
  <p>
    To understand the scientific potential of our scientists, we present some predictive
    mathematical algorithms that can be tested in real-time.
    Presented algorithms are currently effectively implemented
    and used in industrial programs.
  </p>
);

export const logoName = <p className="logoName">ArmMath</p>;

export const PredictionDescription = ({ backPred, width, prediction, mobile }) => {
  const { protonation, solubility, volatility } = { ...prediction };
  return (
    <div
      className="protonation_desc"
      id={backPred ? "fade-outpredpagenum" : "fade-inpreddesc"}
    >
      {!mobile && <h2>
        Prodiction of {width && <br></br>}
        {protonation
          ? "Protonation"
          : solubility
            ? "Solubility"
            : "Volatility"}
      </h2>}
      {protonation && (
        <>
          <p>
            Prediction of the protonation percentage of in a multispecies mixture containing acids.
            An organic base that can exist in a free base
            (fb) and orbitrated forms.The free base form of organic base is volatile and sensible in gas
            phase, whereas the protonated base is not volatile. Usually, the percentages of protonation are determined
            experimentally. Depending on the number of species in the
            mixture, the conduction of experiments can be complicated.
            So, the theoretical estimation of protonation percentages using
            the equilibrium constants becomes crucial. ArmMath developed
            models that allow  to predict the protonation percentage of
            organic bases equilibrium constants for bases and acids.
            Considering the coupled chemical reactions for base acids the
            protonation percentage of organic base has been predicted
            theoretically.
          </p>
        </>
      )}
      {solubility && (
        <>
          <p>
            Aerosolization is the process of releasing liquid substances into the form of micron-size particles, which can be transported by airflow.
            The most important parameter in aerosolization of a compound is the volatility
            of the compound. The volatility of a
            solute in a mixture
            depends on the interactions of the solute with the solvent
            mixture.
            Since the volatility of a compound in a liquid mixture
            differs
            from the volatility of the pure compound, mathematical
            modelling must be used to predict the volatility of active compounds
            in a mixture. Development of such mathematical models require
            proper evaluation of energy interaction parameters of active
            compounds in combination with mixtures.ArmMath developed
            mathematical models for precision of volatility and
            solubility of active
            experimental data.
          </p>
        </>
      )}
      {volatility && (
        <>
          <p>
            Prediction of the volatility (Henry's Constant) of active compounds in  a multicomponent solve.Aerosolization is the process of
            releasing liquid substances into the form of micron-size particles, which can be
            transported  by airflow.
            For non-thermal aerosolization of the
            compounds the most important parameter is the solubility of
            active compounds in the mixtures. In order to determine the
            aerosolization potential of compounds, a chemistry based
            mathematical solubility model is needed to be developed and
            used to
            predict the solubility of the compounds in mixtures.ArmMath
            developed mathematical models for precision of
            volatility and solubility of active compounds. The obtained
            models have been
            validated using experimental data.
          </p>
        </>
      )}
    </div>
  );
};

export const solubilityTitle =
  "Please use the trial version bellow to compute the solubility of lbuprofen in water/PG mixture.";
export const volatilityTitle =
  "Please use the trial version bellow to compute the solubility of lbuprofen in water/PG mixture.";
export const protonationTitle =
  "Please use the trial version bellow to compute the protonation of nicotine.";

export const solubilityPlaceholder =
  "Insert the mass fraction of PG between 0 and 1";
export const volatilityPlaceholder =
  "Insert temperature between 290.15 and 630.15 Kelvin";
export const protonationPlaceholder =
  "Insert amount of benzoic acid between 0.03 and 1.5 grams";

export const AboutUsDescription = ({ more, descAnimate, descAnimateBack, width, mobile }) => {
  const { page1, page2 } = { ...descAnimate };
  const { page1Back, page2Back } = { ...descAnimateBack };

  return (
    <div className="about_us_desc" >
      {more === 1 && !width && !mobile && (
        <div
          id={
            page1 ? "fade-outpredpagenum" : page1Back ? "fade-inaboutdesc" : ""
          }
        >
          Mathematical modelling in partnership with computational science is
          nowadays a strong tool
          in solving real-world problems, i.e. problems coming from the
          industry. It starts from modeling
          physical, chemical or other type phenomena and ends up with
          giving qualitative and
          quantitative descriptions of the industrial process,
          understanding and optimizing
          manufacturing processes and predicting and controlling the
          above mentioned phenomena.
          Mathematics is not a goal in itself, but rather a means to
          get answers in a non-mathematical
          world. Hundreds of industrial labs are created to conduct
          basic as well as fundamental
          research in many non-mathematical areas. Nevertheless, they
          are not able to solve all rising
          industrial problems with the scientific technologies they
          have under their disposal. The
          experience shows that big gaps occur in the field of
          mathematical modeling. There is usually a tremendous lack of
          models which describe the processes precisely. Sometimes the existing
          mathematical models used by the industry show
          non-satisfactory results and need several
          corrections and modifications.
        </div>
      )}
      {more === 2 && !width && !mobile && (
        <div
          id={
            page2 ? "fade-outpredpagenum" : page2Back ? "fade-inaboutdesc" : ""
          }
        >
          ArmMath is a good working place for above mentioned tandem. Experts of
          the company
          represent different areas of mathematics, computer science,
          physics, chemistry, etc. The
          close collaboration between product research scientists from
          industries and scientists of
          ArmMath in the framework of different projects results in
          obtaining many new promising ideas
          in a very short period of time. The scientists are always
          ready to recommend the industry on
          several fundamental modifications of the mathematical model
          used by the industry which will
          for sure contribute to the goodness of deserved results.
        </div>
      )}
      {more === 3 && !width && !mobile && (
        <>
          ArmMath in the framework of different projects results in obtaining
          many new promising ideas
          in a very short period of time. The scientists are always
          ready to recommend the industry on
          several fundamental modifications of the mathematical model
          used by the industry which will
          for sure contribute to the goodness of deserved results.
          ArmMath has a big pool of high
          qualified scientists, which are experts in different areas of
          mathematics, mathematical
          modeling and applied sciences. Therefore we expect a very
          intensive collaboration with our
          colleagues from academy and industry and will be happy to
          get involved in different research
          projects.
        </>
      )}
      {more === 1 && width && (
        <div>
          Mathematical modelling in partnership with computational science is nowadays a strong tool in
          solving real-world problems, i.e. problems coming from the industry. It starts from modeling
          physical, chemical or other type phenomena and ends up with giving qualitative
          and quantitative descriptions of the industrial process, understanding and optimizing manufacturing processes
          and predicting and controlling the above mentioned phenomena.
        </div>
      )}
      {more === 2 && width && (
        <div>
          Mathematics is not a goal in itself, but rather a means to get answers in a non-mathematical
          world. Hundreds of industrial labs are created to conduct basic as well as fundamental research
          in many non-mathematical areas. Nevertheless, they are not able to solve all rising industrial
          problems with the scientific technologies they have under their disposal. The experience shows
          that big gaps occur in the field of mathematical modeling.
        </div>
      )}
      {more === 3 && width && (
        <div>
          There is usually a tremendous lack of
          models which describe the processes precisely.
          Sometimes the existing mathematical models used by the industry show non-satisfactory results
          and need several corrections andmodifications. ArmMath is a good working place for above
          mentioned tandem. Experts of the company represent different areas of mathematics, computer
          science, physics, chemistry, etc.
        </div>
      )}
      {more === 4 && width && (
        <div>
          The close collaboration between product research scientists
          from industries and scientists of ArmMath in the framework of different projects results in
          obtaining many new promising ideas in a very short period of time.
          The scientists are always ready to recommend the industry on several fundamental
          modifications of the mathematical model used by the industry which will for sure
          contribute to the goodness of deserved results.
        </div>
      )}
      {more === 5 && width && (
        <div>
          ArmMath in the framework of different
          projects results in obtaining many new promising ideas in a very short period of time.
          ArmMath has a big pool of high qualified scientists, which are
          experts in different areas of mathematics, mathematical modeling and applied sciences.
          Therefore we expect a very intensive collaboration with our colleagues from academy and
          industry and will be happy to get involved in different research projects.
        </div>
      )}
      {more === 1 && mobile && (
        <div>
          Mathematical modelling in partnership with computational science is nowadays a strong tool
          in solving real-world problems, i.e. problems coming from the industry.
          It starts from modeling physical, chemical or other type phenomena and ends up
          with giving qualitative and quantitative descriptions of the industrial process,
          understanding and optimizing manufacturing processes and predicting and controlling
          the above mentioned phenomena. Mathematics is not a goal in itself, but rather a means
          to get answers in a non-mathematical world. Hundreds of industrial labs are created to
          conduct basic as well as fundamental research in many non-mathematical areas. Nevertheless,
          they are not able to solve all rising industrial problems with the scientific technologies
          they have under their disposal. The experience shows that big gaps occur in the field of
          mathematical modeling.
        </div>
      )}
      {more === 2 && mobile && (
        <div>
          There is usually a tremendous lack of models which describe the processes precisely.
          Sometimes the existing mathematical models used by the industry show non-satisfactory
          results and need several corrections andmodifications.
          ArmMath is a good working place for above mentioned tandem. Experts of
          the company represent different areas of mathematics, computer science, physics,
          chemistry, etc. The close collaboration between product research scientists from
          industries and scientists of ArmMath in the framework of different projects results
          in obtaining many new promising ideas in a very short period of time. The scientists
          are always ready to recommend the industry on several fundamental modifications of
          the mathematical model used by the industry which will for sure contribute to the
          goodness of deserved results.
        </div>
      )}
      {more === 3 && mobile && (
        <div>
          ArmMath in the framework of different projects results in obtaining many new promising
          ideas in a very short period of time. The scientists are always ready to recommend
          the industry on several fundamental modifications of the mathematical model used by
          the industry which will for sure contribute to the goodness of deserved results.
          ArmMath has a big pool of high qualified scientists, which are experts in different
          areas of mathematics, mathematical modeling and applied sciences. Therefore we expect
          a very intensive collaboration with our colleagues from academy and industry and will
          be happy to get involved in different research projects.
        </div>
      )}
    </div>
  );
};

export const teamImgGrid9 = [
  {
    src: "./images/karen.png",
    bg: "./images/grid9.png",
  },
  {
    src: "./images/arthur.png",
    bg: "./images/grid9.png",
  },
  {
    src: "./images/anna.jpg",
    bg: "./images/grid9.png",
  }
];
export const teamImgGrid10 = [
  {
    src: "./images/Aghavard.png",
    bg: "./images/grid10.png",
  },
  {
    src: "./images/davit.jfif",
    bg: "./images/grid10.png",
  },
  {
    src: "./images/gevorg.jpg",
    bg: "./images/grid9.png",
  },
];
export const teamImgGrid11 = [
  {
    src: "./images/Oleg.png",
    bg: "./images/grid11.png",
  },
  {
    src: "./images/volodya.png",
    bg: "./images/grid11.png",
  },
  {
    src: "./images/vardges.png",
    bg: "./images/grid11.png",
  },
];
export const teamImgGrid12 = [
  {
    src: "./images/Metaksia.png",
    bg: "./images/grid12.png",
  },
  {
    src: "./images/armen.png",
    bg: "./images/grid12.png",
  },
  {
    src: "./images/tigran.jpg",
    bg: "./images/grid12.png",
  },
];

export const teamInfoGrid15 = [
  {
    name: "Karen",
    surname: "Davtyan",
    position: "CEO",
    bg: "#36635C",
  },
  {
    name: "Arthur",
    surname: "Vardanyan",
    position: "Computer Scientist - Researcher",
    bg: "#36635C",
  },
  {
    name: "Anna",
    surname: "Tadevosyan",
    position: "Chemist - Researcher",
    bg: "#36635C",
  }
];
export const teamInfoGrid16 = [
  {
    name: "Aghavard",
    surname: "Khachatryan",
    position: "Mathematical Modeler Leading researcher",
    bg: "#C09D81",
  },
  {
    name: "David",
    surname: "Karapetyan",
    position: "Senior Software Developer",
    bg: "#C09D81",
  },
  {
    name: "Gevorg",
    surname: "Arakelyan",
    position: "Mathematician-programmer",
    bg: "#C09D81",
  },
];
export const teamInfoGrid17 = [
  {
    name: "Oleg",
    surname: "Kamalyan",
    position: "Chemist - Researcher",
    bg: "#C77347",
  },
  {
    name: "Volodya",
    surname: "Vardanyan",
    position: "Software Developer",
    bg: "#C77347",
  },
  {
    name: "Vardges",
    surname: "Grigoryan",
    position: "Mathematician-programmer",
    bg: "#C77347",
  },
];
export const teamInfoGrid18 = [
  {
    name: "Metaksia",
    surname: "Avetisyan",
    position: "Computer Scientist - Researcher",
    bg: "#C6BCB0",
  },
  {
    name: "Armen",
    surname: "Davtyan",
    position: "Software Developer",
    bg: "#C6BCB0",
  },
  {
    name: "Tigran",
    surname: "Davtyan",
    position: "Mathematician-programmer",
    bg: "#C6BCB0",
  },
];

export const teamHoverInfo1 = [
  [
    {
      pos: "CEO",
      desc: "15+ years in Project Management and Human Resource management.Author of 3 scientific articles",
      bg: "#36635C",
      name: "Karen",
      left: "",
    },
    {
      pos: "Doctor of Science in Physics and Mathematics, Professor",
      desc: "35+ years in mathematics and theoretical  mechanics.Author of about 75 papers and 18 theses.",
      bg: "#C09D81",
      name: "Aghavard",
      left: width < 1440 ? true : "",
    },
    {
      pos: "Doctor of Science in Chemistry, Professor",
      desc: "40+ years in chemistry and research. Author of about 100 scientific-educational-methodological works.",
      bg: "#C77347",
      name: "Oleg",
      left: "",
    },
    {
      pos: "PHD in Mathematics",
      desc: "4+ years in Mathematics and Computer Science. Author of 4 scientific articles, 1 international and 1 local conference thesis.",
      bg: "#C6BCB0",
      name: "Metaksia",
      left: true,
    },
  ],
  [
    {
      pos: "PHD candidate in Mathematics",
      desc: "5+ years in Mathematics and Computer Science. Author of 5 scientific articles, 1 international and 1 local conference thesis.",
      bg: "#36635C",
      name: "Arthur",
      left: "",
    },
    {
      pos: "Senior Software Developer",
      desc: "10+ years in Software development and engineering. Scope of technologies: Javascript, Node.js, React.js, PHP, MySQL, Neo4j",
      bg: "#C09D81",
      name: "David",
      left: width < 1440 ? true : "",
    },
    {
      pos: "Software Developer",
      desc: "5+ years in Software development and engineering. Scope of technologies: Javascript, Node.js, React.js, C#, MySQL",
      bg: "#C77347",
      name: "Volodya",
      left: "",
    },
    {
      pos: "Software Developer",
      desc: "3+ years in Software development and engineering. Scope of technologies: Javascript, Node.js, React.js, MySQL",
      bg: "#C6BCB0",
      name: "Armen",
      left: true,
    },
  ],
  [
    {
      pos: "Chemist - Researcher",
      desc: "15+ years in Chemistry and HR",
      bg: "#36635C",
      name: "Anna",
      left: "",
    },
    {
      pos: "Mathematician-programmer",
      desc: "2+ years in Mathematics and Computer Science",
      bg: "#C09D81",
      name: "Gevorg",
      left: "",
    },
    {
      pos: "Mathematician-programmer",
      desc: "2+ years in Mathematics and Computer Science",
      bg: "#C77347",
      name: "Vardges",
      left: "",
    },
    {
      pos: "Mathematician-programmer",
      desc: "2+ years in Mathematics and Computer Science",
      bg: "#C6BCB0",
      name: "Tigran",
      left: true,
    },
  ]
];
export const teamImgMob1 = [
  {
    src: "./images/karen.png",
    bg: "./images/grid9.png",
  },
  {
    src: "./images/Metaksia.png",
    bg: "./images/grid12.png",
  },
  {
    src: "./images/volodya.png",
    bg: "./images/grid11.png",
  },
  {
    src: "./images/gevorg.jpg",
    bg: "./images/grid9.png",
  },
];
export const teamImgMob2 = [
  {
    src: "./images/Aghavard.png",
    bg: "./images/grid10.png",
  },
  {
    src: "./images/arthur.png",
    bg: "./images/grid9.png",
  },
  {
    src: "./images/armen.png",
    bg: "./images/grid12.png",
  },
  {
    src: "./images/vardges.png",
    bg: "./images/grid11.png",
  },
];
export const teamImgMob3 = [
  {
    src: "./images/Oleg.png",
    bg: "./images/grid11.png",
  },
  {
    src: "./images/davit.jfif",
    bg: "./images/grid10.png",
  },
  {
    src: "./images/anna.jpg",
    bg: "./images/grid9.png",
  },
  {
    src: "./images/tigran.jpg",
    bg: "./images/grid12.png",
  },
];
export const teamMobInfo1 = [
  {
    name: "Karen",
    surname: "Davtyan",
    position: "CEO",
    bg: "#36635C",
  },
  {
    name: "Metaksia",
    surname: "Avetisyan",
    position: "Computer Scientist - Researcher",
    bg: "#C6BCB0",
  },
  {
    name: "Volodya",
    surname: "Vardanyan",
    position: "Software Developer",
    bg: "#C77347",
  },
  {
    name: "Gevorg",
    surname: "Arakelyan",
    position: "Mathematician-programmer",
    bg: "#C09D81",
  },
];
export const teamMobInfo2 = [
  {
    name: "Aghavard",
    surname: "Khachatryan",
    position: "Mathematical Modeler Leading researcher",
    bg: "#C09D81",
  },
  {
    name: "Arthur",
    surname: "Vardanyan",
    position: "Computer Scientist - Researcher",
    bg: "#36635C",
  },
  {
    name: "Armen",
    surname: "Davtyan",
    position: "Software Developer",
    bg: "#C6BCB0",
  },
  {
    name: "Vardges",
    surname: "Grigoryan",
    position: "Mathematician-programmer",
    bg: "#C77347",
  },


];
export const teamMobInfo3 = [
  {
    name: "Oleg",
    surname: "Kamalyan",
    position: "Chemist - Researcher",
    bg: "#C77347",
  },
  {
    name: "David",
    surname: "Karapetyan",
    position: "Senior Software Developer",
    bg: "#C09D81",
  },
  {
    name: "Anna",
    surname: "Tadevosyan",
    position: "Chemist - Researcher",
    bg: "#36635C",
  },
  {
    name: "Tigran",
    surname: "Davtyan",
    position: "Mathematician-programmer",
    bg: "#C6BCB0",
  },

];
export const teamHoverInfoMob = [
  [
    {
      pos: "CEO",
      desc: "15+ years in Project Management and Human Resource management.Author of 3 scientific articles",
      bg: "#36635C",
      name: "Karen",
      left: "",
    },
    {
      pos: "Doctor of Science in Physics and Mathematics, Professor",
      desc: "35+ years in mathematics and theoretical  mechanics.Author of about 75 papers and 18 theses.",
      bg: "#C09D81",
      name: "Aghavard",
      left: "",
    },
    {
      pos: "Doctor of Science in Chemistry, Professor",
      desc: "40+ years in chemistry and research. Author of about 100 scientific-educational-methodological works.",
      bg: "#C77347",
      name: "Oleg",
      left: true,
    },

  ],
  [
    {
      pos: "PHD in Mathematics",
      desc: "4+ years in Mathematics and Computer Science. Author of 4 scientific articles, 1 international and 1 local conference thesis.",
      bg: "#C6BCB0",
      name: "Metaksia",
      left: '',
    },
    {
      pos: "PHD candidate in Mathematics",
      desc: "5+ years in Mathematics and Computer Science. Author of 5 scientific articles, 1 international and 1 local conference thesis.",
      bg: "#36635C",
      name: "Arthur",
      left: "",
    },
    {
      pos: "Senior Software Developer",
      desc: "10+ years in Software development and engineering. Scope of technologies: Javascript, Node.js, React.js, PHP, MySQL, Neo4j",
      bg: "#C09D81",
      name: "David",
      left: true,
    },

  ],
  [
    {
      pos: "Software Developer",
      desc: "5+ years in Software development and engineering. Scope of technologies: Javascript, Node.js, React.js, C#, MySQL",
      bg: "#C77347",
      name: "Volodya",
      left: "",
    },
    {
      pos: "Software Developer",
      desc: "3+ years in Software development and engineering. Scope of technologies: Javascript, Node.js, React.js, MySQL",
      bg: "#C6BCB0",
      name: "Armen",
      left: '',
    },
    {
      pos: "Chemist - Researcher",
      desc: "15+ years in Chemistry and HR",
      bg: "#36635C",
      name: "Anna",
      left: true,
    },

  ],
  [
    {
      pos: "Mathematician-programmer",
      desc: "2+ years in Mathematics and Computer Science",
      bg: "#C09D81",
      name: "Gevorg",
      left: "",
    },
    {
      pos: "Mathematician-programmer",
      desc: "2+ years in Mathematics and Computer Science",
      bg: "#C77347",
      name: "Vardges",
      left: "",
    },
    {
      pos: "Mathematician-programmer",
      desc: "2+ years in Mathematics and Computer Science",
      bg: "#C6BCB0",
      name: "Tigran",
      left: true,
    },

  ],

];



