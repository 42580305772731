import React from "react";

const TeamHoverInfo = ({ pos, desc, bg, left, mobile }) => {
  return (
    <div className="team_member_info" 
    id={left && !mobile? "fade-inteamdescleft" : !mobile? "fade-inteamdesc": ''}
     style={left? { background: bg, left: '-100%' }: { background: bg}}
     >
      <h3>{pos}</h3>
      <p>
        {desc}
      </p>
    </div>
  )
}

export default TeamHoverInfo