import React from "react";

const AboutUsImgContent = () => {
  return (
    <div className="about_us_container">
      <img src="./images/aboutus.png" className="about_us_img" alt="aboutus" />
      <img src="./images/aboutbg.png" className="about_us_img" alt="aboutusbg" />
      <img src="./images/aboutrec.png" className="about_us_img" alt="aboutrec" />
      <div className="about_us_content">
        <h1>The possible collaboration fields involve</h1>
        <ul>
          <li>Fluid dynamics</li>
          <li>Data analysis</li>
          <li>Parameter identification</li>
          <li>Heat transfer by conduction, diffusion and induction</li>
          <li>Heat transfer by conduction, diffusion and induction</li>
          <li>Population growth dynamics</li>
          <li>Optimization of parameters</li>
          <li>Stability issues</li>
          <li>Homogenization</li>
        </ul>
      </div>
    </div>
  )
}

export default AboutUsImgContent