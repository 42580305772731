import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Grid from "../../components/Grid";
import Page from "../../components/Page";
import PageDescription from "../../components/PageDescription";
import AboutUsTablet from "../AboutUsTablet";
import AboutUsMobile from "../AboutUsMobile";
import { logoName, AboutUsDescription } from "../../constants";

const width = window.innerWidth

const AboutUs = () => {
    const [more, setMore] = useState(1)
    const [descAnimate, setDescAnimate] = useState({
        page1: false,
        page2: false,
    })
    const [descAnimateBack, setDescAnimateBack] = useState({
        page1Back: false,
        page2Back: false,
    })

    if (width < 1140  && width > 481) {
        return <AboutUsTablet   more={more} setMore={setMore} paginate={'16px'}  />
    }
    if (width < 481) {
        return <AboutUsMobile   more={more} setMore={setMore} paginate={'16px'}  />
    }

    return (
        <>
            <Header page="about" />
            <Grid
                page={<Page page="3" />}
                logoName={logoName}
                description={
                    <PageDescription
                        title="About Us"
                        content={<AboutUsDescription
                            more={more}
                            descAnimate={descAnimate}
                            descAnimateBack={descAnimateBack}
                           
                        />}
                      width="52vw"
                    />}
                location="about"
                setMore={setMore}
                more={more}
                setDescAnimate={setDescAnimate}
                setDescAnimateBack={setDescAnimateBack}
            />
            <Footer paginate={'20px'} />
        </>
    )
}

export default AboutUs