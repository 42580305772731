import Homepage from "./Homepage"
import Projects from "./Projects"
import AboutUs from "./AboutUs"
import Team from "./Team/Team"
import ContactsMobile from "./ContactsMobile"

const config = [
  {
    path: '/',
    element: <Homepage />
  },
  {
    path: '/projects',
    element: <Projects />
  },
  {
    path: '/about',
    element: <AboutUs />
  },
  {
    path: '/team',
    element: <Team />
  },
  {
    path: '/contact',
    element: <ContactsMobile />
  },
]

export default config