import React from "react";
import Page from "../../components/Page";
import PageDescription from "../../components/PageDescription";
import GridTablet from "../../components/GridTablet";
import { homepageContent } from '../../constants/index'
import HeaderTablet from "../../components/HeaderTablet";
import FooterTablet from "../../components/FooterTablet";

const HomepageTablet = () => {
  const imageSmall = (
    <div className="img_sm_container">
      <img src="./images/homepageS.png" className="img_small" alt="homepageS" />
    </div>
  );
  return (
    <>
      <div>
        <img src="./images/homepage.png" className="img_homepage_tab" alt="homepage" />
      </div>
      <HeaderTablet />
      <GridTablet
        location="homepage"
        page={<Page page={"1"} width={1140} />}
        imageSmall={imageSmall}
        description={<PageDescription 
          title={"ArmMath"}  
          content={homepageContent}
          location="homepage"
           />}
      />
      <FooterTablet />
    </>
  );
};

export default HomepageTablet;
