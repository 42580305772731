import React from "react";
import { ReactComponent as ErrorSvg } from '../assets/error.svg'

const Error = ({ error }) => {
    return (
        <div className="error">
            <ErrorSvg />
            <p>{error}</p>
        </div>
    )
}

export default Error