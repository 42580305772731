import React from "react";
import PageCard from "./PageCard";
import Prediction from "./PredictionCard";
import AboutUsImgContent from "./AboutUsImgContent";
import {
  teamImgGrid9,
  teamImgGrid10,
  teamImgGrid11,
  teamImgGrid12,
  teamInfoGrid15,
  teamInfoGrid16,
  teamInfoGrid17,
  teamInfoGrid18
}
  from "../constants";
import TeamImg from "./TeamImg";
import TeamInfo from "./TeamInfo";
import { ReactComponent as Arrow } from '../assets/arrow.svg'
import { ReactComponent as Circle } from '../assets/circle.svg'
import { ReactComponent as ArrowLeft } from '../assets/arrowleft.svg'

const GridTablet = ({
  location,
  page,
  imageSmall,
  description,
  prediction,
  setPrediction,
  values,
  setValues,
  more,
  setMore,
  setSlideMembers,
  slideMembers
}) => {
  const { protonation, solubility, volatility } = { ...prediction };

  const readMoreRight = () => {
    setMore(more + 1)
  }
  const readMoreLeft = () => {
    setMore(more - 1)
  }


  return (
    <div className="grid_tablet">
      <div className="grid_1 border"> </div>
      <div
        className="grid_2 border"
        style={
          (location === "projects" || (location === 'team'))
            ? { borderRight: "none", borderBottom: "none", width: "29.82vw" }
            : location === "about" ? { borderRight: "none", width: "29.82vw" } : {}
        }
      >
        {location === "projects" && (
          <Prediction
            prediction={prediction}
            setPrediction={setPrediction}
            title="Protonation percentage"
            setValues={setValues}
            values={values}
            width={1140}
          />
        )}
        {(protonation || solubility || volatility) &&
          (setPrediction,
            (
              <PageCard
                page="2"
                prediction={prediction}
                location={location}
                width={1140}
                setPrediction={setPrediction}
              />
            ))}
        {location === 'about' && (
          <PageCard
            page="4"
            location={location}
          />
        )}
        {location === 'team' &&
          teamImgGrid9.map((img, i) => (
            i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
          ))
        }
      </div>
      <div className="grid_3 border"
        style={(location === 'team' && slideMembers !== 3) ?
          { borderRight: "none", borderBottom: "none", width: "29.82vw" } : {}}
      >
        {location === "projects" && (
          <Prediction
            prediction={prediction}
            setPrediction={setPrediction}
            title="Solubility"
            setValues={setValues}
            values={values}
            width={1140}
          />
        )}
        {location === 'about' && (
          <AboutUsImgContent

          />
        )}
        {location === 'team' &&
          teamImgGrid10.map((img, i) => (
            i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
          ))
        }
      </div>
      <div className="grid_4"> </div>
      <div className="grid_5 border">
        <div className="line_logo">
          <div className="line_"></div>
          <p className="logoName_">ArmMath</p>
        </div>
      </div>
      <div className="grid_6 border"
        style={(location === 'team' && slideMembers !== 3) ?
          { borderRight: "none", borderBottom: "none", width: "29.82vw" } : {}}
      >
        {location === "homepage" && (
          <PageCard page="2" location={location} width={1140} />
        )}
        {location === "projects" && (
          <Prediction
            prediction={prediction}
            setPrediction={setPrediction}
            title="Volatility"
            width={1140}
            setValues={setValues}
            values={values}
          />
        )}
        {location === 'team' &&
          teamInfoGrid15.map((item, i) => (
            i === slideMembers &&
            <TeamInfo
              key={i}
              name={item.name}
              surname={item.surname}
              position={item.position}
              bg={item.bg}
              slideMembers={slideMembers}
            />
          ))}
      </div>
      <div className="grid_7 border"
        style={(location === 'team' && slideMembers !== 3) ?
          { borderRight: "none", borderBottom: "none", width: "29.82vw" } : {}}
      >
        {location === 'team' &&
          teamInfoGrid16.map((item, i) => (
            i === slideMembers &&
            <TeamInfo
              key={i}
              name={item.name}
              surname={item.surname}
              position={item.position}
              bg={item.bg}
              slideMembers={slideMembers}
            />
          ))}
      </div>
      <div className="grid_8"> </div>
      <div className="grid_9 border">
        {location === "homepage" && page}
        {location === "projects" && (!protonation && !solubility && !volatility) && page}
        {location === "about" && page}
        {location === 'team' && slideMembers !== 0 &&
          <div className="arrow_tablet" >
            <div className="team_arrow"
              onClick={() => setSlideMembers(slideMembers - 1)}
            >
              <ArrowLeft className="arrow" />
              <Circle className="circleleft" />
            </div>
          </div>}
      </div>
      <div className="grid_10 border"
        style={(location === 'team' && slideMembers !== 3) ?
          { borderRight: "none", borderBottom: "none", width: "29.82vw" } : {}}
      >
        {location === 'team' &&
          teamImgGrid11.map((img, i) => (
            i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
          ))
        }
      </div>
      <div className="grid_11 border"
        style={(location === 'team' && slideMembers !== 3) ?
          { borderRight: "none", borderBottom: "none", width: "29.82vw" } : {}}
      >
        {imageSmall}
        {location === "projects" &&
          !protonation &&
          !solubility &&
          !volatility && (
            <PageCard
              page="3"
              location={location}
              prediction={prediction}
              setPrediction={setPrediction}
            />
          )}
        {location === 'team' &&
          teamImgGrid12.map((img, i) => (
            i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
          ))
        }
      </div>
      <div className="grid_12">
        {location === 'team' && slideMembers !== 3 &&
          <div className="arrow_wrapper">
            <div className="team_arrow"
              onClick={() => setSlideMembers(slideMembers + 1)}
            >
              <Arrow className="arrow" />
              <Circle className="circle" />
            </div>
          </div>
        }
      </div>
      <div className="grid_13">
        {location === "homepage" && description}
        {location === "projects" && (!protonation && !solubility && !volatility) && description}
        {location === "about" && description}
        {location === 'about' && more !== 1 &&
          <div className="arrow_left" onClick={readMoreLeft}>
            <Circle className="circle" />
            <ArrowLeft className="arrow" />
          </div>}
      </div>
      <div className="grid_14"
        style={(location === 'team' && slideMembers !== 3) ?
          { borderRight: "none", width: "29.82vw" } : {}}
      >
        {location === 'team' &&
          teamInfoGrid17.map((item, i) => (
            i === slideMembers &&
            <TeamInfo
              key={i}
              name={item.name}
              surname={item.surname}
              position={item.position}
              bg={item.bg}
              slideMembers={slideMembers}
            />
          ))}
      </div>
      <div className="grid_15"
        style={(location === 'team' && slideMembers !== 3) ?
          { borderRight: "none", width: "29.82vw" } : {}}
      >
        {location === "about" && more !== 5 &&
          <div className="read">Read</div>}
        {location === 'team' &&
          teamInfoGrid18.map((item, i) => (
            i === slideMembers &&
            <TeamInfo
              key={i}
              name={item.name}
              surname={item.surname}
              position={item.position}
              bg={item.bg}
              slideMembers={slideMembers}
            />
          ))}
      </div>
      <div className="grid_16">
        {location === 'about' && more !== 5 &&
          <div className="slide_text">
            <p>more</p>
            <div className="flex_2" onClick={readMoreRight}>
              <Arrow className="arrow" />
              <Circle className="circle" />
            </div>
          </div>}

      </div>
    </div>
  );
};

export default GridTablet;
