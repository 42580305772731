import React from "react";


const PageDescription = ({ title, content, animate, location, prediction, backPred, top, width, mobile }) => {

    const { protonation, solubility, volatility } = { ...prediction }

    return (
        <div>
            <div className="description"
                id={((location === "projects" && !protonation && !solubility && !volatility) || backPred) ? "fade-intext" : (location === "projects" && (protonation || solubility || volatility)) ? "fade-outpredpagenum" : animate}
                style={(location === 'homepage') && !top ?
                    { top: '-23%' } : location === 'about' && !mobile ?
                        { top: '-27%' } : top ? { top: '-8vh' } : width ? { width: '52vw' } : mobile ? { left: 0, paddingLeft: '6px', width: '95vw', top: '-65%' } : {}}
            >
                <h2>{title}</h2>
                <div>
                    {content}
                </div>
            </div>
            <div className="line_wrapper"> <span className="line"></span></div>
        </div>
    )
}

export default PageDescription