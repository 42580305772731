import React from "react";
import HeaderMobile from "../../components/HeaderMobile";
import GridMobile from "../../components/GridMobile";
import Page from "../../components/Page";



const TeamMobile = ({ paginate, slideMembers, setSlideMembers }) => {
    return (
        <>
            <HeaderMobile />
            <GridMobile
                location={"team"}
                slideMembers={slideMembers}
                setSlideMembers={setSlideMembers}
                paginat={paginate}
                page={<Page page="4"/>}
                title={"Our Team"}
            />
        </>
    )
}

export default TeamMobile