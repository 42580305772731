import React, { useState } from "react";
import {
  protonation as protonationValue,
  solubility as solubilityValue,
  volatility as volatilityValue
} from "../helpers";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";


const PredictionContent = ({ title, solubility, placeholder, volatility, backPred, setValues, protonation, values }) => {

  const [value, setValue] = useState()

  const Error = () => {
    toast.error("Please fill input field!", {
      position: toast.POSITION.TOP_CENTER
    });

  };

// const handleChange = (e) => {
//   console.log(e)
//   if (protonation && e.target.value && e.key === "Enter") {
//     const payload = async () => {
//       const result = await protonationValue(e.target.value)
//       setValues(result)
//     }
//     payload()
//   }
//   if (solubility && e.target.value && e.key === "Enter") {
//     const payload = async () => {
//       const result = await solubilityValue(e.target.value)
//       setValues(result)
//     }
//     payload()
//   }
//   if (volatility && e.target.value && e.key === "Enter") {
//     const payload = async () => {
//       const result = await volatilityValue(e.target.value)
//       setValues(result)
//     }
//     payload()
//   }
//   if (!e.target.value) {
//     Error()
//   }
// }


  const handleCalculate = (e) => {
    if(e && e.key !== 'Enter') return
    if (protonation && value) {
      const payload = async () => {
        const result = await protonationValue(value)
        setValues(result)
      }
      payload()
    }
    if (solubility && value) {
      const payload = async () => {
        const result = await solubilityValue(value)
        setValues(result)
      }
      payload()
    }
    if (volatility && value) {
      const payload = async () => {
        const result = await volatilityValue(value)
        setValues(result)
      }
      payload()
    }
    if (!value) {
      Error()
    }
  }


  return (
    <>
      <div
        className="protonation"
        id={((solubility || volatility) && !backPred) ? "fade-insolopen" : backPred ? 'fade-outpred' : 'fade-inpredopen'}
        style={solubility ? { background: '#C09D81' } : volatility ? { background: '#C6BCB0' } : {}}
      >
        <div className="flex_3">
          <h3>{title}</h3>
          <input
            placeholder={placeholder}
            onChange={(e) => {
              setValue(e.target.value)
             
            }}
            type='number'
            className="input"
            onKeyDown={(e) => handleCalculate(e)}
          />
          <button onClick={() => handleCalculate()}>Calculate</button>
        </div>
      </div>
      <ToastContainer
        autoClose={1000}
      />
    </>
  )
}

export default PredictionContent