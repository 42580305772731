import React from "react";
import HeaderTablet from "../../components/HeaderTablet";
import FooterTablet from "../../components/FooterTablet";
import GridTablet from "../../components/GridTablet";
import Page from "../../components/Page";


const TeamTablet = ({ paginate, slideMembers, setSlideMembers }) => {
    return (
        <>
            <HeaderTablet
                page={<Page page="4" width={1140} />}
                location="team"
            />
            <GridTablet
            slideMembers={slideMembers}
            setSlideMembers={setSlideMembers}
            location="team"

             />
            <FooterTablet paginate={paginate} />
        </>
    )
}

export default TeamTablet