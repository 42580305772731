import React from "react";
import { ReactComponent as Arrow } from "../assets/arrowWhite.svg";
import { useNavigate } from "react-router-dom";

const PageCard = ({
  page,
  location,
  animate,
  setAnimate,
  prediction,
  setBackPred,
  backPred,
  width,
  setPrediction,
  className,
  mobile,
}) => {
  const { protonation, solubility, volatility } = { ...prediction };

  const navigate = useNavigate();

  const handleClick = () => {
    if (location !== "projects" && location !== "about" && !width) {
      setTimeout(() => {
        navigate("/projects");
      }, 780);
    }
    if (location === "projects" && page === "2" && !width) {
      setBackPred(true);
    }
    if (location === "projects" && page === "3") {
      navigate("/about");
    }
    if (location === "about") {
      navigate("/team");
    }
    if (width && location === "homepage") {
      navigate("/projects");
    }
    if (location === "projects" && page === "2" && width) {
      setPrediction(false);
    }
  };

  return (
    <div
      className={`page_card ${className}`}
      id={
        location === "projects" && !protonation && !solubility && !volatility
          ? "fade-inpred"
          : location === "projects" &&
            (protonation || solubility || volatility) &&
            !backPred
          ? "fade-outpredpage"
          : backPred
          ? "fade-inpredpage"
          : location === "about"
          ? "fade-inpageabout"
          : animate?.main
      }
      style={
        mobile && location === "about"
          ? { width: "33.33%", height: "125px" }
          : {}
      }
    >
      {(location === "homepage" || protonation || solubility || volatility) &&
        !backPred && <h3>Pilot Projects</h3>}
      {location === "projects" &&
        !protonation &&
        !solubility &&
        !volatility && <h3>About Us</h3>}
      {location === "about" && <h3>Team</h3>}
      {backPred && <h3>About Us</h3>}
      <div
        onClick={() => {
          if (location === "homepage" && !width) {
            setAnimate({
              main: "fade-out",
              img: "fade-outimg",
              page: "fade-outpage",
              imgBg: "fade-outbg",
              logo: "fade-outlogo",
            });
          }
          handleClick();
        }}
        className="card_content"
      >
        {(protonation || solubility || volatility) && !backPred && (
          <Arrow style={{ rotate: "180deg" }} />
        )}
        <div className="card_numbers">
          <p className="num_0">0</p>
          <p className="num_page">{page}</p>
        </div>
        {!protonation && !solubility && !volatility && <Arrow />}
        {backPred && <Arrow />}
      </div>
    </div>
  );
};

export default PageCard;
