import React from "react";
import GridTablet from "../../components/GridTablet";
import HeaderTablet from "../../components/HeaderTablet";
import FooterTablet from "../../components/FooterTablet";
import Page from "../../components/Page";
import { AboutUsDescription } from "../../constants";
import PageDescription from "../../components/PageDescription";



const AboutUsTablet = ({ more, setMore, paginate }) => {
    return (
        <>
            <HeaderTablet />
            <GridTablet
                location="about"
                page={<Page page="3" width={1140}/>}
                description={
                    <PageDescription
                        title="About Us"
                        content={<AboutUsDescription
                            more={more}
                            width={1140}
                        />}
                        location="about"
                    />}
                    more={more}
                    setMore={setMore}
            />
            <FooterTablet paginate={paginate} />
        </>
    )
}


export default AboutUsTablet