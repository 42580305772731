import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const width = window.innerWidth

const Menu = ({ setShowMenu, burger, setShowContact, mobile }) => {

  const [menuBar, setMenuBar] = useState(true)

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && burger.current && !burger.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const handleClick = () => {
    setShowContact(true)
    setMenuBar(false)
    setShowMenu(false)
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);

    };
  }, [ref.current, burger.current]);

  const el = (document.getElementsByClassName("grid_mob_homepage")[0] || document.getElementsByClassName("grid_mobile")[0])
  const el2 = document.getElementsByClassName("header_mobile")[0]
  const height = el?.scrollHeight + el2?.offsetHeight

  return (
    <>
      {menuBar && <div className="menu_tablet" style={mobile? {height: height}: {}}>
        <div className="flex_4" ref={ref}>
          <Link to="/projects">Projects</Link>
          <Link to="/about">About Us</Link>
          <Link to="/team">Team</Link>
          <div className="contact_us_menu" onClick={handleClick}>Contact Us</div>
          {width < 481 && <Link to="/contact">Contacts</Link>}
        </div>
      </div>}
    </>
  );
};

export default Menu;
