import React, { useState, useRef } from "react";
import Menu from "./Menu";
import ContactUs from "../pages/ContactUs";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as Burger } from "../assets/menu.svg";
import { useNavigate } from "react-router-dom";


const HeaderTablet = ({ page, location }) => {
  const [showContact, setShowContact] = useState(false)
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const burger = useRef()

  return (
    <>
      {showContact && <ContactUs setShowContact={setShowContact} />}
      {showMenu && <Menu setShowMenu={setShowMenu} burger={burger} setShowContact={setShowContact} />}
      <div className="navbar_tablet">
        <div className="grid_1 border">
          <Logo className="logo" onClick={() => navigate("/")} />
        </div>
        <div className="grid_2 border">
          {page}
        </div>
        <div className="grid_3 border">
          {location === 'team' && <h2 className="title_team">Our Team</h2>}
        </div>
        <div className="grid_4">
          <Burger ref={burger} className="menu" onClick={() => setShowMenu(!showMenu)} />
        </div>
      </div>
    </>
  );
};

export default HeaderTablet;
