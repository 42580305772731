import React from "react";
import HeaderMobile from "../../components/HeaderMobile";
import GridMobile from "../../components/GridMobile";
import Page from "../../components/Page";
import PageDescription from "../../components/PageDescription";
import { projectsContent } from "../../constants";
import Error from "../../components/Error";
import { PredictionDescription } from "../../constants";

const ProjectsMobile = ({ values, setValues, prediction, setPrediction }) => {

  const { protonation, solubility, volatility } = { ...prediction }

  return (
    <>
     
    
      <HeaderMobile prediction={prediction} location="projects"  />
      <GridMobile
        location="projects"
        values={values}
        setValues={setValues}
        prediction={prediction}
        setPrediction={setPrediction}
        page={
          <Page
            location="projects"
            page={"2"}
            prediction={prediction}
            mobile={true}
          />
        }
        description={
          <PageDescription
            location="projects"
            content={projectsContent}
            prediction={prediction}
          />
        }
        title={"Pilot Projects for testing"}
        paginate="8px"
      />
    </>
  )
}

export default ProjectsMobile