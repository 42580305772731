import React, { useEffect, useRef } from "react";
import TeamHoverInfo from "./TeamHoverInfo";
import { teamHoverInfo1, teamHoverInfoMob } from "../constants";
import { useState } from "react";

const TeamInfo = ({ name, surname, position, bg, slideMembers, mobile }) => {
  const [showInfo, setShowInfo] = useState(false);
  
  const ref = useRef(null);
  const team = useRef(null);

  const showInfoTeam = () => {
    setShowInfo(!showInfo);
  };

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      team.current &&
      !team.current.contains(event.target)
    ) {
      setShowInfo(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className="team_info"
      style={{ background: bg }}
      id="fade-inteam"
      onClick={showInfoTeam}
      ref={team}
    >
      <div className="team_info_container">
        <div>
          <h3>{name}</h3>
          <h3>{surname}</h3>
        </div>
        <div>
          <p>{position}</p>
        </div>
      </div>
      {mobile
        ? teamHoverInfoMob[slideMembers].map(
            (item, i) =>
              name === item.name &&
              showInfo && (
                <div key={i} ref={ref}>
                  <TeamHoverInfo
                    pos={item.pos}
                    desc={item.desc}
                    bg={item.bg}
                    left={item.left}
                    mobile={true}
                  />
                </div>
              )
          )
        : teamHoverInfo1[slideMembers].map(
            (item, i) =>
              name === item.name && (
                <TeamHoverInfo
                  key={i}
                  pos={item.pos}
                  desc={item.desc}
                  bg={item.bg}
                  left={item.left}
                />
              )
          )}
    </div>
  );
};

export default TeamInfo;
