import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Grid from "../../components/Grid";
import Page from "../../components/Page";
import HomepageTablet from "../HomepageTablet";
import PageDescription from "../../components/PageDescription";
import HomepageMobile from '../HomepageMobile';
import { homepageContent } from '../../constants/index'


const width = window.innerWidth


const Homepage = () => {

    const [animate, setAnimate] = useState({
        main: null,
        img: null,
        page: null,
        bgImg: null,
        logo: null

    })
    const imageSmall =
        <div className="img_sm_container">
            <img src="./images/homepageS.png" className="img_small" id={animate.img} alt="homepageS" />
            <img src="./images/r.png" className="img_sm_bg" id={animate.img} alt="rec" />
        </div>
    const logoName = <p className="logoName" id={animate.logo}>ArmMath</p>

    if (width < 1140 && width > 481) {
        return <HomepageTablet />
    }
    if (width < 481) {
        return <HomepageMobile />
    }

    return (
        <>
            <div>
                <img src="./images/homepage.png" className="img_homepage" id={animate.imgBg} alt="homepage" />
                <img src="./images/bghome.png" className="img_homepage" id={animate.imgBg} alt="bghome" />
                <img src="./images/rechome.png" className="rectangle" id={animate.imgBg} alt="rechome" />
            </div>
            <Header />
            <Grid
                animate={animate}
                setAnimate={setAnimate}
                page={<Page animate={animate.page} page={'1'} />}
                description={<PageDescription animate={animate.main} title={'ArmMath'} content={homepageContent} />}
                logoName={logoName}
                imageSmall={imageSmall}
                location="homepage"
            />
            <Footer />
        </>
    )
}

export default Homepage