import React from "react";
import HeaderMobile from "../../components/HeaderMobile";
import GridMobile from "../../components/GridMobile";
import Page from "../../components/Page";
import PageDescription from "../../components/PageDescription";
import { homepageContent } from "../../constants";


const HomepageMobile = () => {
  const imageSmall =
    <div className="img_sm_container">
      <img src="./images/homepageS.png" className="img_small" alt="homepageS" />
      <img src="./images/r.png" className="img_sm_bg" alt="rec" />
    </div>
  return (
    <>
     
      <HeaderMobile location="homepage"/>
      <GridMobile
        location="homepage"
        imageSmall={imageSmall}
        page={<Page page="1" width={481} />}
        description={<PageDescription
          content={homepageContent}
          location="homepage"
          top={true}
        />}
        paginate='0'
        title="ArmMath"
      />
    </>
  )
}

export default HomepageMobile