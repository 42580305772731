import React from "react";

const TeamImg = ({ src, bg }) => {

  return (
    <div className="team_img" id='fade-inteam'>
      <img src={src} alt="team" />
      <img src={bg} alt="teambg" />
    </div>
  )
}

export default TeamImg