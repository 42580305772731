import React from "react";


const Page = ({ page, animate, location, prediction, backPred, width, mobile, bottom }) => {
    const { protonation, solubility, volatility } = { ...prediction }

    return (
        <div className="page"
            id={((protonation || solubility || volatility) && !backPred) ? "fade-outpredpagenum" : "fade-intext"}
            style={width && !bottom && (page === '1' || page === '3') ?
                { bottom: '20%' } : width && page === '4' ?
                    { bottom: '-17px' } : mobile ? { bottom: 0 } : bottom? {bottom: "40%"}: {}}
        >
            <p className="num_0">0</p>
            <p className="num_page" id={location === 'projects' ? 'fade-inpage' : animate}>{page}</p>
        </div>
    )
}

export default Page