import React from "react";
import PageCard from "./PageCard";
import Prediction from "./PredictionCard";
import AboutUsImgContent from "./AboutUsImgContent";
import TeamImg from "./TeamImg";
import TeamInfo from "./TeamInfo";
import
{ teamImgGrid9, 
    teamImgGrid10, 
    teamImgGrid11, 
    teamImgGrid12,
    teamInfoGrid15,
    teamInfoGrid16,
    teamInfoGrid17,
    teamInfoGrid18 
  } 
    from "../constants";
import { ReactComponent as Arrow } from '../assets/arrow.svg'
import { ReactComponent as Circle } from '../assets/circle.svg'
import { ReactComponent as ArrowLeft } from '../assets/arrowleft.svg'


const Grid = ({ page,
    description,
    logoName,
    imageSmall,
    location,
    animate,
    setAnimate,
    prediction,
    setPrediction,
    backPred,
    setBackPred,
    setValues,
    setMore,
    more,
    values,
    setDescAnimate,
    setDescAnimateBack,
    slideMembers,
    setSlideMembers
 }) => {

    const { protonation, solubility, volatility } = { ...prediction }

    const readMoreRight = () => {
        if (more === 1) {
            setDescAnimate({
                page1: true
            })
        }
        if (more === 2) {
            setDescAnimate({
                page2: true
            })
        }
        setTimeout(() => {
            setMore(more + 1)
        }, 500)

    }

    const readMoreLeft = () => {
        setDescAnimate(false)
        if (more === 2) {
            setDescAnimateBack({
                page1Back: true
            })
        }
        if (more === 3) {
            setDescAnimateBack({
                page2Back: true
            })
        }
        setMore(more - 1)
    }

    return (
        <div className="grid wrapper">
            <div className="grid_1 border">
                {page}
            </div>
            <div className="grid_2 border">

            </div>
            <div className="grid_3 border">
            {location === 'team' && slideMembers !==0 &&
             <div className="arrow_wrapper">
             <div className="team_arrow"
              onClick={() => setSlideMembers(slideMembers - 1)}
              >
                 <ArrowLeft className="arrow" />
                 <Circle className="circleleft"  />
             </div>
         </div>} 
            </div>
            <div className="grid_4 border" style={location === "about" ? { borderRight: 'none' } : {}}>
                {location === 'about' &&
                    <PageCard
                        page="4"
                        location={location}
                    />}
            </div>
            {location !== 'about' &&
                <div className="grid_5 border"> </div>
            }
            {location !== 'about' &&
                <div className="grid_6 border">
                    {location === 'team' && slideMembers !==2 &&
                        <div className="arrow_wrapper">
                            <div className="team_arrow"
                             onClick={() => setSlideMembers(slideMembers + 1)}
                             >
                                <Arrow className="arrow" />
                                <Circle className="circle" />
                            </div>
                        </div>
                    }
                </div>
            }
            <div className="grid_7 border">
                {description}
            </div>
            <div className="grid_8 border">

            </div>
            <div className="grid_9 border" style={location === "team" ? { borderRight: 'none', borderBottom: 'none' } : {}}>
                {location === 'team' &&
                teamImgGrid9.map((img, i) => (
                   i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
                ))
               }
            </div>
            <div className="grid_10 border"
                style={location === "projects" ? { borderRight: 'none' } : (location === "team" && slideMembers !== 3) ?
                    { borderRight: 'none', borderBottom: 'none' } : {}}
            >
                {location === 'projects' &&
                    <Prediction
                        prediction={prediction}
                        setPrediction={setPrediction}
                        title="Protonation percentage"
                        backPred={backPred}
                        setBackPred={setBackPred}
                        setValues={setValues}
                        values={values}
                    />}
                {location === 'team' &&
                    teamImgGrid10.map((img, i) => (
                        i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
                     ))}
            </div>
            {location !== 'about' &&
                <div className="grid_11 border" style={location === "projects" ?
                    { borderRight: 'none' } :(location === "team" && slideMembers !== 3) ?
                        { borderRight: 'none', borderBottom: 'none' } : {}}
                >
                    {location === 'projects' &&
                        <Prediction
                            prediction={prediction}
                            setPrediction={setPrediction}
                            title="Solubility"
                            backPred={backPred}
                            setBackPred={setBackPred}
                            setValues={setValues}
                            values={values}
                        />}
                    {location === 'team' &&
                        teamImgGrid11.map((img, i) => (
                            i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
                         ))}
                </div>}
            {location !== 'about' &&
                <div className="grid_12 border" style={location === "projects" ?
                    { borderRight: 'none' } : (location === "team" && slideMembers !== 3) ?
                        { borderRight: 'none', borderBottom: 'none' } : {}}
                >
                    {location === 'projects' &&
                        <Prediction
                            prediction={prediction}
                            setPrediction={setPrediction}
                            title="Volatility"
                            backPred={backPred}
                            setBackPred={setBackPred}
                            setValues={setValues}
                            values={values}
                        />}
                    {location === 'team' &&
                       teamImgGrid12.map((img, i) => (
                        i === slideMembers && <TeamImg key={i} src={img.src} bg={img.bg} />
                     ))}
                </div>}
            <div className="grid_13">
                {logoName}
                {(more === 2 || more === 3) &&
                    <div className="arrow_left" onClick={readMoreLeft}>
                        <Circle className="circle" />
                        <ArrowLeft className="arrow" />
                    </div>}
            </div>
            <div className="grid_14"> </div>
            <div className="grid_15"
                style={location === "homepage" ? { borderRight: 'none' } : location === "team" ?
                    { borderRight: 'none' } : {}}
            >
                {imageSmall}
                {(location === 'projects' && ((!protonation && !solubility && !volatility) || backPred)) &&
                    <PageCard
                        page="3"
                        location={location}
                        prediction={prediction}
                        backPred={backPred}
                        setPrediction={setPrediction}
                    />}
                {location === "about" && more !== 3 &&
                    <div className="read">Read</div>}
                {location === 'team' &&
                     teamInfoGrid15.map((item, i) => (
                        i === slideMembers && 
                        <TeamInfo
                        key={i} 
                        name={item.name}
                        surname={item.surname}
                        position={item.position}
                        bg={item.bg}
                        slideMembers={slideMembers}
                         />
                     ))}
            </div>
            <div className="grid_16"
                style={(location === "team" && slideMembers !== 3) ?
                    { borderRight: 'none' } : {}}
            >
                {(protonation || solubility || volatility) && !backPred &&
                    <PageCard
                        page="2"
                        setBackPred={setBackPred}
                        prediction={prediction}
                        location={location}
                    />}
                {location === 'homepage' &&
                    <PageCard
                        animate={animate}
                        setAnimate={setAnimate}
                        page="2"
                        location={location}
                        setPrediction={setPrediction}
                    />}
                {location === 'about' && more !== 3 &&
                    <div className="slide_text">
                        <p>more</p>
                        <div className="flex_2" onClick={readMoreRight}>
                            <Arrow className="arrow" />
                            <Circle className="circle" />
                        </div>
                    </div>}
                {location === 'team' &&
                    teamInfoGrid16.map((item, i) => (
                        i === slideMembers && 
                        <TeamInfo 
                        key={i}
                        name={item.name}
                        surname={item.surname}
                        position={item.position}
                        bg={item.bg}
                        slideMembers={slideMembers}
                         />
                     ))}
            </div>
            {
                location !== 'about' && 
                <div className="grid_17"
                    style={(location === "team" && slideMembers !== 3) ?
                        { borderRight: 'none' } : {}}
                >
                    {location === 'team' &&
                         teamInfoGrid17.map((item, i) => (
                            i === slideMembers && 
                            <TeamInfo 
                            key={i}
                            name={item.name}
                            surname={item.surname}
                            position={item.position}
                            bg={item.bg}
                            slideMembers={slideMembers}
                             />
                         ))}

                </div>
            }
            {
                location !== 'about' && <div className="grid_18"
                    style={(location === "team" && slideMembers !== 3) ?
                        { borderRight: 'none' } : {}}
                >
                    {location === 'team' &&
                        teamInfoGrid18.map((item, i) => (
                            i === slideMembers && 
                            <TeamInfo 
                            key={i}
                            name={item.name}
                            surname={item.surname}
                            position={item.position}
                            bg={item.bg}
                            slideMembers={slideMembers}
                             />
                         ))}
                </div>
            }
            {
                location === 'about' && <div className="grid_19">
                    <AboutUsImgContent />
                </div>
            }
        </div >
    )
}

export default Grid