export const protonation = async (benzoic) => {
    try {
        const payload = await fetch(`https://solve-math.analysed.ai/protonation?w=${benzoic}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        })
        const res = await payload.json();
        if (typeof res === 'number') return res.toFixed(2)
        return res;

    } catch (e) {
        console.log(e)
    }
}
export const solubility = async (ibuprofen) => {
    try {
        const payload = await fetch(`https://solve-math.analysed.ai/solubility?m=${ibuprofen}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        })
        const res = await payload.json();
        if (typeof res === 'number') return res.toFixed(2)
        return res

    } catch (e) {
        console.log(e)
    }
}
export const volatility = async (kelvin) => {
    try {
        const payload = await fetch(`https://solve-math.analysed.ai/volatility?T=${kelvin}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
        })
        const res = await payload.json();
        if (typeof res === 'number') return res.toFixed(2)
        return res

    } catch (e) {
        console.log(e)
    }
}

