import React, { useState, useRef } from 'react'
import Menu from './Menu';
import { useNavigate } from "react-router-dom"
import ContactUs from '../pages/ContactUs';
import { ReactComponent as Burger } from "../assets/menu.svg";
import { ReactComponent as Logo } from "../assets/logo.svg";



const HeaderMobile = ({location, prediction}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showContact, setShowContact] = useState(false)
  const burger = useRef()
  const navigate = useNavigate()
  const { protonation, solubility, volatility } = { ...prediction };

  return (
    <>
      {showMenu && <Menu setShowMenu={setShowMenu} burger={burger} setShowContact={setShowContact} mobile={true} />}
      <div className="header_mobile" style={location === 'homepage'? {height: '60px'} : (protonation || solubility || volatility) ? {height: '60px'}: {height: '125px'}}>
        <div className="grid_1 border">
          <Burger className='burger' onClick={() => setShowMenu(!showMenu)} ref={burger} />
        </div>
        <div className="grid_2 border">
          <div className='logo_wrapper'>
            <Logo className='logo' onClick={() => navigate('/')} />
            <h2>ArmMath</h2>
          </div>
        </div>
        <div className="grid_3">
          <div className='line'></div>
        </div>
      </div>
      {showContact && <ContactUs setShowContact={setShowContact} />}
    </>
  )
}

export default HeaderMobile