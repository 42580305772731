import React, { useState } from "react";
import ContactUs from "../pages/ContactUs";
import { ReactComponent as Logo } from '../assets/logo.svg'
import { Link, useNavigate } from 'react-router-dom'


const Header = ({ page }) => {
    const [showContact, setShowContact] = useState(false)
    const navigate =  useNavigate()
    return (
        <>
            {showContact && <ContactUs setShowContact={setShowContact} />}
            <div className="grid navbar">
                <div className="grid_1 border">
                    <Logo className="logo"  onClick={() => navigate('/')}  />
                </div>
                <div className="grid_2 border">
                    <div className="flex_2">
                        <Link to="/projects" style={page === 'projects' ? { textDecoration: "revert" } : {}}>Projects</Link>
                        <Link to="/about" style={page === 'about' ? { textDecoration: "revert" } : {}}>About Us</Link>
                    </div>
                </div>
                <div className="grid_3 border">
                    <div className="flex_2">
                        <Link to="/team" style={page === 'team' ? { textDecoration: "revert" } : {}}>Team</Link>
                        <div className="link_about" onClick={() => setShowContact(true)}>Contact Us</div>
                    </div>
                </div>
                <div className="grid_4 border"> </div>
                <div className="grid_5 border"> </div>
                <div className="grid_6"> </div>
            </div >
        </>
    )
}

export default Header